import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { Button, Col, Drawer, Image, Layout, Row } from "antd";
import imageLogo from "../images/logo_white.svg";
import { HashLink } from "react-router-hash-link";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import AOS from "aos";

import "aos/dist/aos.css";

AOS.init();

const { Header, Footer, Content } = Layout;

const DefaultLayout = () => {
  const { height, width } = useWindowDimensions();
  const cutOff = 992;

  const [menuExpanded, setMenuExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isMobile = () => {
    return width < 992;
  };

  return (
    <Layout>
      <Header className="bg-primary fixed w-full h-24 z-50">
        <div className="flex flex-1 justify-between items-stretch h-full w-full max-w-7xl mx-auto">
          <div className="flex flex-1 items-center">
            <Image src={imageLogo} className="w-10 md:w-16" preview={false} />
          </div>
          <div className="flex flex-1 justify-end items-center">
            {!isMobile() && (
              <>
                <HashLink
                  smooth
                  to={"#about-us"}
                  className="text-white hover:text-custom_yellow/80 text-xl h-fit mx-6"
                >
                  About us
                </HashLink>
                <HashLink
                  smooth
                  to={"#our-framework"}
                  className="text-white hover:text-custom_yellow/80 text-xl h-fit mx-6"
                >
                  Our framework
                </HashLink>
                <HashLink
                  smooth
                  to={"#contact-us"}
                  className="bg-white hover:bg-white/80 text-primary hover:text-primary text-xl px-12 h-12 rounded-md inline-flex items-center ml-6"
                >
                  Contact us
                </HashLink>
              </>
            )}
            {isMobile() && (
              <Button
                icon={<MenuOutlined className="text-white" />}
                onClick={showDrawer}
              ></Button>
            )}
          </div>

          <Drawer
            title={false}
            placement="right"
            onClose={onClose}
            open={open}
            headerStyle={{
              backgroundColor: "#008900",
              color: "white",
            }}
            bodyStyle={{
              backgroundColor: "#008900",
              color: "white",
            }}
            closeIcon={<CloseOutlined className="text-white" />}
          >
            <HashLink
              smooth
              to={"#about-us"}
              className="text-white hover:text-custom_yellow/80 text-xl h-fit mb-6 block"
              onClick={onClose}
            >
              About us
            </HashLink>
            <HashLink
              smooth
              to={"#our-framework"}
              className="text-white hover:text-custom_yellow/80 text-xl h-fit mb-6 block"
              onClick={onClose}
            >
              Our framework
            </HashLink>
            <HashLink
              smooth
              to={"#contact-us"}
              className="bg-white hover:bg-white/80 text-primary hover:text-primary text-xl px-12 h-12 rounded-md inline-flex items-center mb-6 block"
              onClick={onClose}
            >
              Contact us
            </HashLink>
          </Drawer>
        </div>
      </Header>

      <Content className="mt-24 bg-white overflow-x-hidden">
        <div className="w-full mx-auto">
          <Outlet />
        </div>
      </Content>

      <Footer className="bg-primary w-full px-4 md:px-24 overflow-x-hidden">
        <Row className="flex flex-1 justify-start items-stretch h-full w-full max-w-7xl mx-auto mt-10 md:mt-20">
          <Col
            span={24}
            md={4}
            className="hidden md:flex justify-start items-start "
            data-aos="fade-down"
            data-aos-delay="100"
            data-aos-duration="600"
          >
            <Image src={imageLogo} className="w-28" preview={false} />
          </Col>
          <Col
            span={24}
            md={9}
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="600"
          >
            <p className="mb-6 md:mb-0 text-white text-bold text-sm md:text-xl font-bold">
              Address
              <br />
              <br className="hidden md:inline" />
              <span className="font-normal md:font-bold">
                19600 NE 19TH Ct, Miami, Florida
                <br />
                Zip Code 33179
              </span>
            </p>
          </Col>
          <Col
            span={24}
            md={9}
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="600"
          >
            <p className="mb-6 md:mb-0 text-white text-bold text-sm md:text-xl font-bold">
              Contact
              <br />
              <br className="hidden md:inline" />
              <span className="font-normal md:font-bold">
                Email:{" "}
                <a
                  href="mailto:hola@stplusplus.com"
                  className="text-white hover:text-custom_yellow"
                >
                  hola@stplusplus.com
                </a>
                <span className="hidden">
                  <br />
                  Tel.: +54 (9) 123 456 7890
                </span>
              </span>
            </p>
          </Col>
        </Row>
        <div className="w-full max-w-7xl mx-auto border-t-2 border-white mt-6 md:mt-20 pt-2">
          <p
            className="text-white text-xs md:text-sm"
            data-aos="fade-left"
            data-aos-delay="500"
            data-aos-duration="600"
            data-aos-offset="-50"
          >
            © Copyright 2022 Technological services - All rights reserved.
          </p>
        </div>
      </Footer>
    </Layout>
  );
};

export default DefaultLayout;
