import React, { useState, useRef } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Spin,
  Typography,
} from "antd";
import { HashLink } from "react-router-hash-link";

import bannerImage from "../images/banner_image.png";
import iconThumb from "../images/thumb-up.svg";
import iconSettings from "../images/settings.svg";
import iconGroup from "../images/group.svg";
import logoEira from "../images/logo_eira.svg";
import logoEt from "../images/logo_et.png";
import logoMas10 from "../images/logo_mas10.svg";
import logoPlusGolf from "../images/logo_plus_golf.png";
import SimpleReactValidator from "simple-react-validator";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { TextArea } = Input;

const Home = () => {
  const [values, setValues] = useState({
    full_name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [, forceUpdate] = useState();

  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
      element: (message) => (
        <div className="text-red-600 pt-2 text-xs">{message}</div>
      ),
    })
  );

  const disabled = () => !simpleValidator.current.allValid();

  const onFinish = async () => {
    setLoading(true);
    setError("");

    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
      forceUpdate();
      setError("All fields form are required.");
      return;
    }

    try {
      const data = {
        full_name: values.full_name,
        email_address: values.email,
        subject: values.subject,
        message: values.message,
        type: "st",
      };

      const res = await postData(
        "https://servicedev.liricus.com.ar/send-email-st",
        data
      );
      if (res && res.status === 200) {
        resetForm();

        setError("");
        setSuccess(
          "Thanks for contacting us! We will be in touch with you shortly."
        );
      } else {
        setError("There was an error trying to send your message.");
        setSuccess("");
      }
      resetForm();
      setLoading(false);
    } catch (e) {
      console.log(e.message);
      setError(e.message);
      setLoading(false);
    }
  };

  const postData = async (url, data) => {
    const response = await fetch(url, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return response;
  };

  const resetForm = () => {
    setValues({
      full_name: "",
      email: "",
      subject: "",
      message: "",
    });
    simpleValidator.current.hideMessages();
    simpleValidator.current.visibleFields = [];
    forceUpdate();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((v) => ({ ...v, [name]: value }));
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    simpleValidator.current.showMessageFor(name);
    setValues((v) => ({ ...v, [name]: value }));
  };

  return (
    <div>
      <div className="flex flex-col justify-end items-center bg-custom_lightgray">
        <Row className="w-full max-w-7xl flex flex-col md:flex-row pt-10 md:pt-0 pb-10 md:pb-0">
          <Col
            span={24}
            md={13}
            className="flex flex-col justify-center items-center md:items-start order-2 md:order-1"
          >
            <h1 className="text-primary text-3xl md:text-5xl leading-none font-bold mb-4 text-center md:text-left">
              Software development
              <br />
              <span className="text-secondary">for sports</span>
            </h1>
            <Text className="text-custom_darkgray text-medium text-2xl mb-6 text-center md:text-left">
              Get in touch with experts in the field
            </Text>
            <HashLink
              smooth
              to={"#contact-us"}
              className="bg-primary text-white hover:text-white/70 rounded-full py-4 px-8 text-xl h-auto font-bold"
            >
              Contact us
            </HashLink>
          </Col>
          <Col span={24} md={11} className="order-1 md:order-2">
            <Image src={bannerImage} preview={false} />
          </Col>
        </Row>
      </div>

      <div className="w-full max-w-7xl mx-auto py-24" id="about-us">
        <h2
          className="text-2xl md:text-4xl	text-primary text-center font-bold mb-10 md:mb-20"
          data-aos="fade-up"
        >
          About us
        </h2>
        <Row gutter={24} className="px-4 md:px-0">
          <Col span={24} className="flex items-center">
            <p
              className="text-xl text-center"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <span className="font-bold text-primary">ST++</span> is a high
              skilled development team with years of experience working together
              with exercises sciences professionals and implementing
              ready-to-use software modules in sport and health areas.
            </p>
          </Col>
        </Row>
      </div>

      <div className="w-full bg-custom_gray rounded-xl py-24">
        <div className="w-full max-w-7xl mx-auto">
          <h2
            className="text-2xl md:text-4xl	text-primary text-center font-bold mb-10 md:mb-20"
            data-aos="fade-up"
          >
            About our services
          </h2>
          <Row gutter={24} className="px-4 md:px-0">
            <Col span={24} md={12} className="flex items-start mb-4 md:mb-0">
              <ul
                className="text-xl h-full w-full bg-white rounded-lg p-8 drop-shadow-md flex flex-col items-center"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <li className="text-center">Systems Integration</li>
                <li className="text-center">Software development</li>
                <li className="text-center">Scalable Infrastructure </li>
                <li className="text-center">QA</li>
              </ul>
            </Col>
            <Col span={24} md={12} className="flex items-start mb-4 md:mb-0">
              <ul
                className="text-xl h-full w-full bg-white rounded-lg p-8 drop-shadow-md flex flex-col items-center"
                data-aos="fade-up"
                data-aos-delay="150"
                data-aos-duration="600"
              >
                <li className="text-center">App development</li>
                <li className="text-center">UX/UI</li>
                <li className="text-center">
                  Customizable team configuration for staffing
                </li>
                <li className="text-center">
                  Experience integrating with major vendor of the industry
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </div>

      <div className="w-full max-w-7xl mx-auto py-24">
        <h2
          className="text-2xl md:text-4xl	text-primary text-center font-bold mb-10 md:mb-20"
          data-aos="fade-up"
        >
          Our Successes
        </h2>

        <Row gutter={24} className="flex flex-row items-stretch px-4 md:px-0">
          <Col
            span={24}
            md={6}
            className="flex mb-4 md:mb-0 cursor-pointer"
            data-aos="fade-up"
            data-aos-duration="600"
            onClick={() => {
              window.open("https://www.endurancegroup.org/index.php/en/");
            }}
          >
            <div className="bg-white drop-shadow-md flex flex-col items-stretch h-full rounded-b-xl transition hover:scale-110">
              <div className="bg-et flex justify-center items-center py-4 rounded mb-6 h-24">
                <Image src={logoEt} preview={false} className="h-12" />
              </div>
              <div className="px-6 pb-6">
                <h3 className="text-custom_darkgray text-2xl font-bold mb-2">
                  Endurance Tool
                </h3>
                <p className="text-justify">
                  Referent in education and tools for{" "}
                  <span className="text-et">endurance sports</span> in Spanish
                  speaking countries.
                </p>
              </div>
            </div>
          </Col>
          <Col
            span={24}
            md={6}
            className="flex mb-4 md:mb-0 cursor-pointer"
            data-aos="fade-up"
            data-aos-delay="150"
            data-aos-duration="600"
            onClick={() => {
              window.open("https://mas10.ar/");
            }}
          >
            <div className="bg-white drop-shadow-md flex flex-col items-stretch h-full rounded-b-xl transition hover:scale-110">
              <div className="bg-mas10 flex justify-center items-center py-4 rounded mb-6 h-24">
                <Image src={logoMas10} preview={false} className="h-12" />
              </div>
              <div className="px-6 pb-6">
                <h3 className="text-custom_darkgray text-2xl font-bold mb-2">
                  Más 10
                </h3>
                <p className="text-justify">
                  Comprehensive application where every amateur{" "}
                  <span className="text-mas10">soccer player</span> has access
                  to services like a professional.
                </p>
              </div>
            </div>
          </Col>
          <Col
            span={24}
            md={6}
            className="flex mb-4 md:mb-0 cursor-pointer"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="600"
            onClick={() => {
              window.open("https://plus.golf/");
            }}
          >
            <div className="bg-white drop-shadow-md flex flex-col items-stretch h-full rounded-b-xl transition hover:scale-110">
              <div className="bg-plus-golf flex justify-center items-center py-4 rounded mb-6 h-24">
                <Image src={logoPlusGolf} preview={false} className="h-16" />
              </div>
              <div className="px-6 pb-6">
                <h3 className="text-custom_darkgray text-2xl font-bold mb-2">
                  PlusGolf
                </h3>
                <p className="text-justify">
                  Comprehensive platform for coverage of the most important{" "}
                  <span className="text-plus-golf">golf tournaments</span> and
                  federations in Latin America.
                </p>
              </div>
            </div>
          </Col>
          <Col
            span={24}
            md={6}
            className="flex mb-4 md:mb-0 cursor-pointer"
            data-aos="fade-up"
            data-aos-delay="450"
            data-aos-duration="600"
            onClick={() => {
              window.open("http://eirahealth.com/");
            }}
          >
            <div className="bg-white drop-shadow-md flex flex-col items-stretch h-full rounded-b-xl transition hover:scale-110">
              <div className="bg-eira flex justify-center items-center py-4 rounded mb-6 h-24">
                <Image src={logoEira} preview={false} className="h-16" />
              </div>
              <div className="px-6 pb-6">
                <h3 className="text-custom_darkgray text-2xl font-bold mb-2">
                  Eira
                </h3>
                <p className="text-justify">
                  Patients remote{" "}
                  <span className="text-custom_red">monitoring system</span>{" "}
                  which integrates sports training for chronic patients..
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="w-full bg-custom_gray rounded-xl py-24"
        id="our-framework"
      >
        <div className="w-full max-w-7xl mx-auto">
          <h2
            className="text-2xl md:text-4xl	text-primary text-center font-bold mb-10"
            data-aos="fade-up"
          >
            Our framework
          </h2>
          <p
            className="text-xl text-center mb-10 md:mb-20 mx-6"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            We are using this Framework's structure made by{" "}
            <a
              href="https://sportstechx.com"
              className="text-primary underline underline-offset-2"
              target="_blank"
            >
              sportstechx
            </a>{" "}
            to help us identify and compare the sector we are immersed in.
            <br />
            The three main sectors are organized based on their target audience,
            on the boards, you will be able to see what our products offer to
            each one of them.
          </p>

          <Row gutter={24} className="flex items-stretch px-4 md:px-0">
            <Col
              span={24}
              md={8}
              className="flex mb-4 md:mb-0"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <div className="bg-white rounded-lg p-8 drop-shadow-md flex flex-col items-center h-full">
                <Image src={iconThumb} preview={false} className="w-20 mb-6" />
                <h3 className="text-primary text-2xl text-center font-bold mb-6">
                  Fans and Content
                </h3>
                <h3 className="text-custom_darkgray text-lg font-bold mb-4 text-center">
                  News and content.
                </h3>
                <p className="text-center mb-6">
                  Newsletter
                  <br />
                  Original content
                  <br />
                  You own plan
                </p>
                <h3 className="text-custom_darkgray text-lg font-bold mb-4 text-center">
                  Fan experience and Social Platform.
                </h3>
                <p className="text-center">
                  Internal communication channels
                  <br />
                  Main social platforms integrations
                  <br />
                  Statistics
                </p>
              </div>
            </Col>
            <Col
              span={24}
              md={8}
              className="flex mb-4 md:mb-0"
              data-aos="fade-up"
              data-aos-delay="150"
              data-aos-duration="600"
            >
              <div className="bg-white rounded-lg p-8 drop-shadow-md flex flex-col items-center h-full">
                <Image
                  src={iconSettings}
                  preview={false}
                  className="w-20 mb-6"
                />
                <h3 className="text-primary text-2xl text-center font-bold mb-6">
                  Management and Organization
                </h3>
                <h3 className="text-custom_darkgray text-lg font-bold mb-4 text-center">
                  Organization and venues
                </h3>
                <p className="text-center mb-6">
                  Team management
                  <br />
                  Event management
                  <br />
                  Team profile
                </p>
                <h3 className="text-custom_darkgray text-lg font-bold mb-4 text-center">
                  Media and commercial partners
                </h3>
                <p className="text-center mb-6">
                  Press coverage
                  <br />
                  Media production
                  <br />
                  Graphic design and game's reporting
                </p>
              </div>
            </Col>
            <Col
              span={24}
              md={8}
              className="flex mb-4 md:mb-0"
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="600"
            >
              <div className="bg-white rounded-lg p-8 drop-shadow-md flex flex-col items-center h-full">
                <Image src={iconGroup} preview={false} className="w-20 mb-6" />
                <h3 className="text-primary text-2xl text-center font-bold mb-6">
                  Activity and Performance
                </h3>
                <h3 className="text-custom_darkgray text-lg font-bold mb-4 text-center">
                  Performance tracking and coach
                </h3>
                <p className="text-center mb-6">
                  Control and Control and activity data
                  <br />
                  Online assistance
                </p>
                <h3 className="text-custom_darkgray text-lg font-bold mb-4 text-center">
                  Infrastructure
                </h3>
                <p className="text-center">
                  E-market
                  <br />
                  Remote access and online update
                  <br />
                  Customized training plan
                  <br />
                  Library of videos
                  <br />
                  Virtual assistant
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="w-full max-w-7xl mx-auto py-24" id="contact-us">
        <Row className="px-4 md:px-0">
          <Col
            span={24}
            md={12}
            className="flex flex-col justify-center mb-10 md:mb-0"
          >
            <h2
              className="text-2xl md:text-4xl	text-primary text-left font-bold mb-6"
              data-aos="fade-right"
              data-aos-duration="600"
            >
              Let's chat.
              <br />
              Tell us about your project.
            </h2>
            <p
              className="text-xl"
              data-aos="fade-right"
              data-aos-delay="150"
              data-aos-duration="600"
            >
              Let's create something together.
            </p>
          </Col>
          <Col span={24} md={12}>
            <Form
              onFinish={onFinish}
              className="bg-custom_lightgray rounded-xl p-6"
              data-aos="fade-left"
              data-aos-duration="600"
            >
              <h3 className="text-2xl md:text-3xl text-primary text-left font-bold mb-10">
                Send us a message
              </h3>
              <Form.Item>
                <Input
                  placeholder="Full name*"
                  name="full_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.full_name || ""}
                  className="bg-primary/10 border-0 rounded-md text-lg py-2 text-custom_lightblack"
                />
                {simpleValidator.current.message(
                  "full_name",
                  values.full_name,
                  "required|min:3|max:50"
                )}
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="Email address*"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email || ""}
                  className="bg-primary/10 border-0 rounded-md text-lg py-2 text-custom_lightblack"
                />
                {simpleValidator.current.message(
                  "email",
                  values.email,
                  "required|email"
                )}
              </Form.Item>
              <Form.Item>
                <Input
                  placeholder="Subject*"
                  name="subject"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.subject || ""}
                  className="bg-primary/10 border-0 rounded-md text-lg py-2 text-custom_lightblack"
                />
                {simpleValidator.current.message(
                  "subject",
                  values.subject,
                  "required|min:3|max:100"
                )}
              </Form.Item>
              <Form.Item>
                <TextArea
                  placeholder="Tell us about your project*"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message || ""}
                  className="bg-primary/10 border-0 rounded-md text-lg leading-normal py-2 text-custom_lightblack"
                  rows={4}
                />
                {simpleValidator.current.message(
                  "message",
                  values.message,
                  "required|min:3|max:400"
                )}
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="bg-primary text-white text-lg font-bold py-2 px-6 h-auto rounded-md"
                  disabled={disabled() || loading}
                >
                  {loading && (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ color: "#d9d9d9" }} />
                      }
                      className="mr-4"
                    />
                  )}{" "}
                  Send message
                </Button>
              </Form.Item>

              {error && (
                <Alert
                  message={error}
                  type="error"
                  className="mb-6"
                  closable
                  onClose={() => setError("")}
                />
              )}

              {success && (
                <Alert
                  message={success}
                  type="success"
                  className="mb-6"
                  closable
                  onClose={() => setSuccess("")}
                />
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
